import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import spriteSVG from "../sprite/sprite-build.svg"

export default function BlogPost(props) {
  const data = props.pageContext

  return (
    <Layout>
      <Helmet>
        <body className="single-post"></body>
      </Helmet>
      <SEO title={data.title} description={data.excerpt} />

      <section className="c-section c-section--pagehead">

        <div className="o-container o-v-middle">
          <div className="o-flex-wrap">
            <h1 className="o-type-6">Cookies Policy</h1>
          </div>

        </div>

      </section>

      <section className="c-section c-section--gradient">

        <div className="o-container">
          
          <div className="c-post-detail">

<section className="c-content-section">
  <p>Last updated: October 05, 2021</p>
  
  <p>This Cookies Policy explains what Cookies are and how We use
  them. You should read this policy so You can understand what type of cookies We
  use, or the information We collect using Cookies and how that information is
  used.</p>
  
  <p>Cookies do not typically contain any information that
  personally identifies a user, but personal information that we store about You
  may be linked to the information stored in and obtained from Cookies. For
  further information on how We use, store and keep your personal data secure,
  see our Privacy Policy.</p>
  
  <p>We do not store sensitive personal information, such as
  mailing addresses, account passwords, etc. in the Cookies We use.</p>
</section>


<section className="c-content-section">
  
  <h2>Interpretation and Definitions</h2>
  
  <h3>Interpretation</h3>
  
  <p>The words of which the initial letter is capitalized have
  meanings defined under the following conditions. The following definitions
  shall have the same meaning regardless of whether they appear in singular or in
  plural.</p>
  
  <h3>Definitions</h3>
  
  <p>For the purposes of this Cookies Policy:</p>
  
  <ul className="o-list c-ul-disc">
    <li><b>Company</b> (referred to as either &quot;the Company&quot;,
    &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Cookies Policy)
    refers to SW7 Asset Management (Singapore) Pte Ltd, One Marina Boulevard
    #28-00, Singapore 018989.</li>
    
    <li><b>Cookies</b> means small files that are placed on Your
    computer, mobile device or any other device by a website, containing details of
    your browsing history on that website among its many uses.</li>
    
    <li><b>Website</b> refers to PAD, accessible from <a
    href="https://www.pad.tech"><span
    >www.pad.tech</span></a></li>
    
    <li><b>You</b> means the individual accessing or using the Website,
    or a company, or any legal entity on behalf of which such individual is
    accessing or using the Website, as applicable.</li>
  </ul>
</section>

<section className="c-content-section">
  
  <h2>The use of the Cookies</h2>
  
  <h3>Type of Cookies We Use</h3>
  
  <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
  Cookies. Persistent Cookies remain on your personal computer or mobile device
  when You go offline, while Session Cookies are deleted as soon as You close
  your web browser.</p>
  
  <p>We use both session and persistent Cookies for the purposes
  set out below:</p>
  
  <div className="u-pl-x2 u-mb-x2">
    <p><b>Necessary / Essential Cookies</b></p>
    
    <p>Type: Session Cookies</p>
    
    <p>Administered by: Us</p>
    
    <p>Purpose: These Cookies are essential to
    provide You with services available through the Website and to enable You to
    use some of its features. They help to authenticate users and prevent
    fraudulent use of user accounts. Without these Cookies, the services that You
    have asked for cannot be provided, and We only use these Cookies to provide You
    with those services.</p>
    
    <p><b>Cookies Policy / Notice Acceptance
    Cookies</b></p>
    
    <p>Type: Persistent Cookies</p>
    
    <p>Administered by: Us</p>
    
    <p>Purpose: These Cookies identify if users
    have accepted the use of cookies on the Website.</p>
    
    <p><b>Functionality Cookies</b></p>
    
    <p>Type: Persistent Cookies</p>
    
    <p>Administered by: Us</p>
    
    <p>Purpose: These Cookies allow us to remember
    choices You make when You use the Website, such as remembering your login
    details or language preference. The purpose of these Cookies is to provide You
    with a more personal experience and to avoid You having to re-enter your
    preferences every time You use the Website.</p>
    
    <p><b>Tracking and Performance Cookies</b></p>
    
    <p>Type: Persistent Cookies</p>
    
    <p>Administered by: Third-Parties</p>
    
    <p>Purpose: These Cookies are used to track
    information about traffic to the Website and how users use the Website. The
    information gathered via these Cookies may directly or indirectly identify you
    as an individual visitor. This is because the information collected is
    typically linked to a pseudonymous identifier associated with the device you
    use to access the Website. We may also use these Cookies to test new
    advertisements, pages, features or new functionality of the Website to see how our
    users react to them.</p>
  </div>
  
  <h3>Your Choices Regarding Cookies</h3>
  
  <p>If You prefer to avoid the use of Cookies on the Website,
  first You must disable the use of Cookies in your browser and then delete the
  Cookies saved in your browser associated with this website. You may use this
  option for preventing the use of Cookies at any time.</p>
  
  <p>If You do not accept Our Cookies, You may experience some
  inconvenience in your use of the Website and some features may not function
  properly.</p>
  
  <p>If You'd like to delete Cookies or instruct your web browser
  to delete or refuse Cookies, please visit the help pages of your web browser.</p>
  
  <ul className="o-list">
    <li>For the Chrome web browser, please visit
    this page from Google: <a
    href="https://support.google.com/accounts/answer/32050"><span>https://support.google.com/accounts/answer/32050</span></a></li>
    
    <li>For the Internet Explorer web browser,
    please visit this page from Microsoft: <a
    href="http://support.microsoft.com/kb/278835"><span >http://support.microsoft.com/kb/278835</span></a></li>
    
    <li>For the Firefox web browser, please visit
    this page from Mozilla: <a
    href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"><span
    >https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</span></a></li>
    
    <li>For the Safari web browser, please visit this
    page from Apple: <a
    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"><span
    >https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</span></a></li>
  </ul>
  
  <p>For any other web browser, please visit your web browser's
  official web pages.</p>
  
  <h3>More Information about Cookies</h3>
  
  <p>You can learn more about cookies: <a
  href="https://www.privacypolicies.com/blog/cookies/"><span >What Are Cookies?</span></a>.</p>
  
  <h3>Contact Us</h3>
  
  <p>If you have any questions about this Cookies Policy, You can
  contact us:</p>
  
  <p>By email: pad@sw7group.com</p>
</section>

</div>
          

        </div>

      </section>

    </Layout>
  )
}
